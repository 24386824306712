import { useLocation } from 'react-router-dom';

// MUI
import { Box, Stack } from '@mui/material'

// Fiber three
import { Canvas } from "@react-three/fiber"
import { Environment, OrbitControls, Center } from "@react-three/drei"

// Images
import imgInfo from './../../assets/img/infobox.png'

//Components
import KimonoMale from '../../models/kimono/male/Model'
import KimonoFemale from '../../models/kimono/female/Model'

const MainCanvas = () => {

    const { state } = useLocation();

    const renderProductCanvas = () => {
        if (state.title === 'ΑΝ∆ΡΙΚΟ') return (<KimonoMale {...state} />)
        if (state.title === 'ΓΥΝΑΙΚΕΙΟ') return (<KimonoFemale {...state} />)
    }

    return (
        <Box
            component="main"
            sx={{ flexGrow: 1, bgcolor: '#eeefef' }}
        >
            <Stack sx={{ height: '100vh' }} mt={5}>
                <Canvas gl={{ preserveDrawingBuffer: true }} shadows camera={{ position: [2, 0, 7], fov: 2.8 }}>
                    <ambientLight intensity={0.5} />
                    <spotLight position={[10, 10, 10]} angle={0.5} penumbra={1} />
                    <Center>
                        {renderProductCanvas()}
                    </Center>
                    <Environment preset="warehouse" resolution={1024} />
                    <OrbitControls minPolarAngle={Math.PI / 2} maxPolarAngle={Math.PI / 2} enableZoom={true} enablePan={true} />
                </Canvas>
                <Box
                    className='infobox'
                    component="img"
                    position='fixed'
                    sx={{
                        bottom: '50px',
                        right: 50,
                        height: 190,
                        width: 118,
                    }}
                    alt="info"
                    src={imgInfo}
                />
            </Stack>

        </Box>
    )
}

export default MainCanvas