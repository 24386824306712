import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate, Link } from 'react-router-dom';

import axios from '../../../../axios'

import { Container, Table, TableCell, TableHead, TableBody, TableRow, Paper, Stack, Grid, Typography } from '@mui/material';

import Topbar from '../../../../components/Layout/Topbar';
import ButtonBack from '../../../../components/Helper/ButtonBack';

import { useDispatch, useSelector } from 'react-redux'
import { fetchOrders } from "../../../../redux/slices/Order";

const Shops = () => {

    const navigate = useNavigate()

    const dispatch = useDispatch();
    const userAuth = useSelector((state) => state.auth.data)

    const orders = useSelector(state => state.order.items)

    const [filter, setFilter] = useState()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        dispatch(fetchOrders())
        setLoading(false)
    }, [loading])


    // Filter function
    useEffect(() => {
        function getUniqueListBy(orders, key) {
            return [...new Map(orders.map(item => [item[key], item])).values()]
        }
        setFilter(getUniqueListBy(orders, 'emailSeller'))
    }, [])

    const state = useLocation()
    console.log(state.state.id)
    return (
        <>
            <Topbar />

            <Container
                maxWidth="xl"
                sx={{
                    flexDirection: 'column',
                    justifyContent: 'center',
                    display: 'flex',
                    height: '100vh'
                }}>
                <Grid container spacing={4} direction='row' sx={{ height: '100%' }}>
                    <Grid container spacing={3} mb={10}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Container maxWidth="xl" sx={{ borderRadius: '10px', mt: 20 }}>
                                <Stack direction={'row'} sx={{ justifyContent: 'space-between' }}>
                                    <Stack direction={'row'} spacing={2}>
                                        <Typography variant='h5'>ΣΤΟΙΧΕΙΑ ΠΑΡΑΓΓΕΛΙΑΣ</Typography>
                                    </Stack>
                                </Stack>
                            </Container>
                            <Container maxWidth="xl" component={Paper} sx={{ borderRadius: '10px', mt: 5 }}>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                    <Grid item xs={12}>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left">Καταστήμα</TableCell>
                                                    <TableCell align="left">Διεύθυνση</TableCell>
                                                    <TableCell align="left">Ταχυδρομικός κώδικας</TableCell>
                                                    <TableCell align="left">Πόλη</TableCell>
                                                    <TableCell align="left">Τηλέφωνο</TableCell>
                                                    <TableCell align="left">Όνοματεπώνυμο υπεύθυνου παραλαβής</TableCell>
                                                    <TableCell align="left">Κωδικος πελάτη</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {orders?.map((obj, idx) => (
                                                    obj.emailSeller === state.state.email ? (
                                                        <TableRow
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell align="left">{obj.storeName}</TableCell>
                                                            <TableCell align="left">{obj.address}</TableCell>
                                                            <TableCell align="left">{obj.postCode}</TableCell>
                                                            <TableCell align="left">{obj.city}</TableCell>
                                                            <TableCell align="left">{obj.phone}</TableCell>
                                                            <TableCell align="left">{obj.recipient}</TableCell>
                                                            <TableCell align="left">{obj.sellerCode}</TableCell>
                                                        </TableRow>) : ''
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                </Grid>
                            </Container>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            <Container sx={{ marginTop: '-50px' }} maxWidth={false} >
                <ButtonBack />
            </Container>
        </>
    )
}

export default Shops