import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from "react-router-dom"
import { useCustomization } from "../../Context/CustomizerContext";
import { Stack, Typography, Box, Divider, Button, styled, IconButton, ButtonGroup, Radio } from '@mui/material';

// Icons
import CloseIcon from '@mui/icons-material/Close';

// Components
import ButtonBack from '../../components/Helper/ButtonBack';

// UID Gen
import uniqid from 'uniqid';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const Configurator = () => {

    const navigate = useNavigate()

    const { state } = useLocation();

    const [count, setCount] = useState(1)

    const handleIncrement = () => {
        setCount(Math.min(count + 1, 3));
    };

    const handleDecrement = () => {
        setCount(Math.max(count - 1, 1));
    };


    const {
        // ΒΑΣΗ ΠΟΔΙΑΣ
        aponBaseColors,
        aponBaseColor,
        setAponBaseColor,
        // ΦΑΣΑ ΠΟΔΙΑΣ
        fasaAponColors,
        fasaAponColor,
        setFasaAponColor,
        // ΤΙΡΑΝΤΕΣ ΠΟΔΙΑΣ
        holderAponColors,
        holderAponColor,
        setHolderAponColor,
        // ΤΣΕΠΗ ΠΟΔΙΑΣ
        pocketAponColors,
        pocketAponColor,
        setPocketAponColor,
        // ΛΟΓΟΤΥΠΟ ΣΤΗΘΟΣ
        logosCenter,
        logoCenter,
        setLogoCenter,
        // ΛΟΓΟΤΥΠΟ ΤΣΕΠΗ
        logosPocket,
        logoPocket,
        setLogoPocket,
        // POSITION
        logosPosition,
        logoPosition,
        setLogoPosition
    } = useCustomization();

    const [selectedValue, setSelectedValue] = useState('front');

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
        setLogoPosition(event.target.value)
    };

    return (
        <Stack sx={{ p: 3, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100vh' }}>
            <Stack>
                <Stack py={2}>
                    <Typography variant='h3' mb={2} sx={{ fontSize: '24px', fontWeight: '700' }}>{state.name}</Typography>
                    <Typography variant='body'>{state.description}</Typography>
                </Stack>
                <Divider />
                <Stack py={3}>
                    <Typography variant='h5' mb={1} sx={{ fontSize: '18px' }}>Τιράντες ποδιάς</Typography>
                    <Stack direction='row' spacing={3} py={1}>
                        {aponBaseColors.map((item, index) => (
                            <Box
                                sx={{
                                    cursor: "pointer",
                                    border: item.color === aponBaseColor.color ? "4px solid #fff" : "4px solid #fff",
                                    outline: item.color === aponBaseColor.color ? "4px solid #ced0d1" : "none",
                                }}
                                key={index}
                                height={35}
                                width={35}
                                backgroundColor={item.color}
                                borderRadius="50%"
                                onClick={() => setAponBaseColor(item)}
                            />
                        ))}
                    </Stack>
                </Stack>
                <Divider />
                <Stack py={3}>
                    <Typography variant='h5' mb={1} sx={{ fontSize: '18px' }}>Λογότυπο πελάτη</Typography>
                    <Stack direction='row' spacing={3} py={1}>
                        <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
                            <Button component="label" variant="contained" size="large"
                                sx={{
                                    background: '#f2c301',
                                    "&.MuiButtonBase-root:hover": {
                                        bgcolor: "#f2c301"
                                    }
                                }}>
                                Upload logo
                                <VisuallyHiddenInput type="file" />
                            </Button>
                            <IconButton>
                                <CloseIcon sx={{ color: '#949d9f' }} />
                            </IconButton>
                        </Stack>
                        <Divider orientation="vertical" />
                        <Stack direction="row" spacing={4}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Radio
                                    checked={selectedValue === 'front'}
                                    onChange={handleChange}
                                    value="front"
                                    sx={{
                                        '& .MuiSvgIcon-root': {
                                            fontSize: 40,
                                        },
                                        color: '#ced0d1',
                                        '&.Mui-checked': {
                                            color: '#ced0d1',
                                        },
                                    }}
                                />
                                <Typography variant='h5' sx={{ fontSize: '18px' }}>Στήθος</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Radio
                                    checked={selectedValue === 'pocket'}
                                    onChange={handleChange}
                                    value="pocket"
                                    sx={{
                                        '& .MuiSvgIcon-root': {
                                            fontSize: 40,
                                        },
                                        color: '#ced0d1',
                                        '&.Mui-checked': {
                                            color: '#ced0d1',
                                        },
                                    }}
                                />
                                <Typography variant='h5' sx={{ fontSize: '18px' }}>Τσέπη</Typography>
                            </Box>
                        </Stack>
                    </Stack>
                </Stack>
                <Divider />
                <Stack py={3}>
                    <Typography variant='h5' mb={1} sx={{ fontSize: '18px' }}>Δευτερεύον λογότυπο Schweppes στην τσέπη</Typography>
                    <Stack direction='row' spacing={2} py={1}>
                        {logosCenter.map((item, index) => (
                            <Box
                                sx={{
                                    cursor: "pointer",
                                    border: "5px solid #fff",
                                    outline: item.name === logoCenter.name ? "4px solid #ced0d1" : "none",
                                }}
                                key={index}
                                height={95}
                                width={95}
                                borderRadius="15px"
                                style={{
                                    backgroundImage: `url(/logo/${item.name}.png)`,
                                    backgroundColor: '#231f20',
                                    backgroundSize: '120%',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center'
                                }}
                                onClick={() => setLogoCenter(item)}
                            />

                        ))}
                    </Stack>
                </Stack>
                <Divider />
                <Stack py={3}>
                    <Typography variant='h5' sx={{ fontSize: '18px' }}>Ποσότητα</Typography>
                    <Typography variant='caption'>*Μέγιστη ποσότητα 3 τεµάχια</Typography>
                    <Stack direction='row' spacing={2} py={3}>
                        <ButtonGroup size="medium" direction='row' sx={{ alignItems: 'center' }}>
                            {<Button onClick={handleDecrement} variant="contained">-</Button>}
                            {<Typography variant="h5" px={2} sx={{ fontSize: '16px' }}>{count}</Typography>}
                            <Button onClick={handleIncrement} variant="contained">+</Button>
                        </ButtonGroup>
                    </Stack>
                </Stack>
            </Stack>

            <Stack py={2}>
                <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} >
                    <ButtonBack/>
                    <Button
                        component="label"
                        variant="contained"
                        size="large"
                        sx={{
                            padding: '8px 40px',
                            background: '#000000',
                            color: '#ffffff',
                            "&.MuiButtonBase-root:hover": {
                                bgcolor: "#000000"
                            }
                        }}>
                        Προσθήκη
                    </Button>
                </Stack>
            </Stack>
        </Stack >
    )
}

export default Configurator