import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import axios from '../../../../../axios'

import { Table, TableCell, TableContainer, TableHead, TableBody, TableRow, Paper, Button, Stack, Grid, Typography, Chip } from '@mui/material';

import SingleOrder from '../SingleOrder';

import { useDispatch, useSelector } from 'react-redux'
import { fetchOrders } from "../../../../../redux/slices/Order";

// Tostofy
import { ToastContainer, toast } from 'react-toastify';

const SellerTable = () => {

    const navigate = useNavigate()

    const dispatch = useDispatch();
    const userAuth = useSelector((state) => state.auth.data)
    const status = useSelector((state) => state.auth.status)

    const orders = useSelector(state => state.order.items)


    const [loading, setLoading] = useState(false)

    useEffect(() => {
        dispatch(fetchOrders())
        setLoading(false)
    }, [loading])

    const declineOrder = async (id) => {
        axios.patch(`/orders/${id}/decline`);

        toast('Η παραγγελία ακυρώθηκε. ', {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            progress: undefined,
            theme: "light",
        });
        setLoading(true)
    }

    const acceptOrder = async (id) => {
        axios.patch(`/orders/${id}/accept`);

        toast.success('Αποδοχη παραγγελιας', {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            progress: undefined,
            theme: "light",
        });
        setLoading(true)
    }

    return (
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell align="left">Κωδικος παραγγελιας</TableCell>
                    <TableCell align="left">Καταστήμα</TableCell>
                    <TableCell align="left">Υπεύθυνος παραλαβής</TableCell>
                    <TableCell align="left">Κωδικος πελάτη</TableCell>
                    <TableCell align="left">Ημερομηνια παραγγελιας</TableCell>
                    <TableCell align="left">Κατάσταση</TableCell>
                    <TableCell align="right"></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {orders?.map((obj, idx) =>

                    status === 'loaded' && obj.user.email === userAuth.email ? (
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={idx}
                        >
                            <TableCell component="th" scope="row">{obj._id}</TableCell>
                            <TableCell align="left">{obj.storeName}</TableCell>
                            <TableCell align="left">{obj.recipient}</TableCell>
                            <TableCell align="left">{obj.sellerCode}</TableCell>
                            <TableCell align="left">{new Date(obj.createdAt).toLocaleDateString()}</TableCell>
                            <TableCell align="left">
                                {obj.status === 'pending' ? <Chip color="primary" label="Σε Αναμονή" /> : ''}
                                {obj.status === 'accept' ? <Chip color="success" label="Ολοκληρώθηκε" /> : ''}
                                {obj.status === 'decline' ? <Chip color="error" label="Ακυρώθηκε" /> : ''}
                            </TableCell>
                            <TableCell align="right">
                                <Stack direction={'row'} spacing={2} justifyContent="flex-end">
                                    <Button variant="outlined" size="medium" sx={{ textTransform: 'unset', borderRadius: '10px' }}
                                        onClick={() => navigate(`/single-order/${obj._id}`, { state: { id: obj._id, status: obj.status } })}
                                    >Προβολή
                                    </Button>
                                </Stack>
                            </TableCell>
                        </TableRow>) : ('')
                )}
            </TableBody>
        </Table>
    )
}

export default SellerTable