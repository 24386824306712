import AponService from '../src/assets/img/ELEMENTS-06.png'
import AponOxford from '../src/assets/img/ELEMENTS-07.png'
import KimonoMale from '../src/assets/img/ELEMENTS-08.png'
import KimonoFemale from '../src/assets/img/ELEMENTS-09.png'

const data = [
    {
        id: 1,
        icon: AponService,
        title: 'SERVICE',
        name: 'ΠΟΔΙΑ SERVICE',
        category: 'ΠΟΔΙΕΣ',
        description: 'Ποδιά λαιµού µε λαιµουδιά µε διπλό πλαστικό ρυθµιστή για εύκολη χρήση. ∆ιπλή τσέπη µε χώρισµα. Υλικό: σύµµεικτο 245gsm / 65% πολυεστέρας - 35% βαµβάκ'
    },
    {
        id: 2,
        icon: AponOxford,
        title: 'OXFORD',
        name: 'ΠΟΔΙΑ OXFORD',
        category: 'ΠΟΔΙΕΣ',
        description: 'Ποδιά λαιµού µε λουριά χιαστί στο πίσω µέρος και µεταλλικό κρίκο. Τριπλή τσέπη µε χώρισµα και µια µικρή τσέπη επάνω στο στήθος µε διακοσµητικά πιρτσίνια. Τσέπες από ύφασµα oxford Υλικό: σύµµεικτο 245gsm 65% / πολυεστέρας - 35% βαµβάκι'
    },
    {
        id: 3,
        icon: KimonoMale,
        title: 'ΑΝ∆ΡΙΚΟ',
        name: 'ΑΝ∆ΡΙΚΟ ΚΙΜΟΝΟ',
        category: 'ΚΙΜΟΝΟ',
        description: 'Κιµονό ανδρικό µε αεραγωγούς στους ώµους που κλείνει µπροστά µε τρουκ. Με µία τσέπη στο στήθος και µία τσέπη στο αριστερό µανίκι. Αναδιπλούµενα µανίκια. Χρώµα µαύρο Υλικό: 150gsm / 100% microfibre'
    },
    {
        id: 4,
        icon: KimonoFemale,
        title: 'ΓΥΝΑΙΚΕΙΟ',
        name: 'ΓΥΝΑΙΚΕΙΟ ΚΙΜΟΝΟ',
        category: 'ΚΙΜΟΝΟ',
        description: 'Κιµονό γυναικείο που κλείνει µπροστά µε τρουκ. ∆ιπλή τσέπη για στυλό στο αριστερό µανίκι. Αναδιπλούµενα µανίκια. Χρώµα µαύρο. Υλικό: σύµµεικτο 165gsm / 65% πολευστέρας - 35% βαµβάκι'
    },
];

export default data