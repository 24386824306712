import { createContext, useContext, useState } from "react";
const aponBaseColors = [
  {
    color: "#11161a",
    name: "ΜΑΥΡΟ",
  },
  {
    color: "#f2c301",
    name: "ΓΚΡΙ",
  },
];

const holderAponColors = [
  {
    color: "#11161a",
    name: "ΜΑΥΡΟ",
  },
  {
    color: "#f2c301",
    name: "ΚΊΤΡΙΝΟ",
  },
];

const fasaAponColors = [
  {
    color: "#11161a6",
    name: "ΜΑΥΡΟ",
  },
  {
    color: "#f2c301",
    name: "ΚΊΤΡΙΝΟ",
  },
];

const pocketAponColors = [
  {
    color: "#201B26",
    name: "ΜΑΥΡΟ",
  },
  {
    color: "#f2c301",
    name: "ΚΊΤΡΙΝΟ",
  },
];

const logosCenter = [
  {
    name: "LOGO-02",
  },
  {
    name: "LOGO-03",
  },
];

const logosPocket = [
  {
    name: "LOGO-02",
  },
  {
    name: "LOGO-03",
  },
];

const logosPosition = [
  {
    name: "Στήθος",
  },
  {
    name: "Τσέπη",
  },
];


//New context
const collarColors = [
  {
    color: "#11161a",
    name: "ΜΑΥΡΟ",
  },
  {
    color: "#d79604",
    name: "ΚΙΤΡΙΝΟ",
  },
];

const Customization = createContext({});

export const CustomizationProvider = (props) => {

  const [aponBaseColor, setAponBaseColor] = useState(aponBaseColors[1]);
  const [holderAponColor, setHolderAponColor] = useState(holderAponColors[2]);

  const [fasaAponColor, setFasaAponColor] = useState(fasaAponColors[0]);
  const [pocketAponColor, setPocketAponColor] = useState(pocketAponColors[0]);


  const [logoCenter, setLogoCenter] = useState(logosCenter[1]);
  const [logoPocket, setLogoPocket] = useState(logosPocket[1]);
  const [logoPosition, setLogoPosition] = useState(logosPosition[0]);

  const [collarColor, setCollarColor] = useState(collarColors[1]);
  const [logoClientUrl, setLogoClientUrl] = useState('/logo/clear.png');

  return (
    <Customization.Provider
      value={{

                // ΒΑΣΗ ΠΟΔΙΑΣ
                aponBaseColors,
                aponBaseColor,
                setAponBaseColor,
                // ΦΑΣΑ ΠΟΔΙΑΣ
                fasaAponColors,
                fasaAponColor,
                setFasaAponColor,
                // ΤΙΡΑΝΤΕΣ ΠΟΔΙΑΣ
                holderAponColors,
                holderAponColor,
                setHolderAponColor,
                // ΤΣΕΠΗ ΠΟΔΙΑΣ
                pocketAponColors,
                pocketAponColor,
                setPocketAponColor,
                // ΛΟΓΟΤΥΠΟ ΣΤΗΘΟΣ
                logosCenter,
                logoCenter,
                setLogoCenter,
                // ΛΟΓΟΤΥΠΟ ΤΣΕΠΗ
                logosPocket,
                logoPocket,
                setLogoPocket,
                // POSITION
                logosPosition,
                logoPosition,
                setLogoPosition,

        // Collar color
        collarColors,
        collarColor,
        setCollarColor,
        // Logo client
        logoClientUrl,
        setLogoClientUrl
      }}
    >
      {props.children}
    </Customization.Provider>
  );
};

export const useCustomization = () => {
  const context = useContext(Customization);
  return context;
};
