import React from 'react'
import { useLocation } from "react-router-dom";

//MUI
import { Container, Grid } from "@mui/material";

// Components
import Topbar from '../../components/Layout/Topbar'

// Data
import data from '../../data'
import ProductCard from '../../components/Menu/ProductCard';
import ButtonBack from '../../components/Helper/ButtonBack';

const Products = () => {

  const state = useLocation()

  return (
    <>
      <Topbar />
      <Container
        maxWidth="lg"
        sx={{
          flexDirection: 'column',
          justifyContent: 'center',
          display: 'flex',
        }}
      >
        <Grid container spacing={4} direction='row' sx={{ height: '100vh', alignContent: 'center' }}>
          {data.filter(item => (item.category === state.state.title)).map((card) => (
            <Grid item xs={12} sm={6} md={6}
              sx={{
                borderRadius: '10px',
              }}>
              <ProductCard {...card} />
            </Grid>
          ))}
        </Grid>
      </Container>
      <Container sx={{ marginTop: '-50px' }} maxWidth={false} >
        <ButtonBack />
      </Container>
    </>
  )
}

export default Products