import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import axios from '../../../../../axios'

import { Table, TableCell, TableContainer, TableHead, TableBody, TableRow, Paper, Button, Stack, Grid, Typography, Chip, MenuItem, Select, FormControl, InputLabel, Modal, Box, TextField, TextareaAutosize } from '@mui/material';

import SingleOrder from '../SingleOrder';

import { useDispatch, useSelector } from 'react-redux'
import { fetchOrders } from "../../../../../redux/slices/Order";

// Tostofy
import { ToastContainer, toast } from 'react-toastify';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const SellerTable = () => {

    const navigate = useNavigate()

    const dispatch = useDispatch();
    const orders = useSelector(state => state.order.items)
    const userAuth = useSelector((state) => state.auth.data)

    const [orderId, setOrderId] = useState()
    const [sellerEmail, setSellerEmail] = useState()
    const [declineMsg, setdDeclineMsg] = useState('')

    const [open, setOpen] = useState(false);

    const handleOpen = (id, email) => {
        setOrderId(id);
        setSellerEmail(email)
        setOpen(true);
    }

    const handleClose = () => setOpen(false);

    const handleChangeStatus = async (id, value) => {
        const data = {
            deliveredStatus: value,
        }
        await axios.patch(`/orders/${id}/status`, data);
        dispatch(fetchOrders())
    };

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        dispatch(fetchOrders())
        setLoading(false)
    }, [loading])

    const declineOrder = async (id, emailSeller) => {
        setOpen(false);
        axios.patch(`/orders/${id}/decline`);

        toast('Η παραγγελία ακυρώθηκε. ', {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            progress: undefined,
            theme: "light",
        });
        setLoading(true)

        const declineData = {
            email: sellerEmail,
            msg: declineMsg,
            id: id
        }
        await axios.post('/orders/decline', declineData)
    }

    const acceptOrder = async (id, email, storeName) => {
        axios.patch(`/orders/${id}/accept`);

        toast.success('Αποδοχη παραγγελιας', {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            progress: undefined,
            theme: "light",
        });
        setLoading(true)

        const acceptData = {
            storeName: storeName,
            email: email,
            id: id
        }
        await axios.post('/orders/accept', acceptData)
    }

    return (
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell align="left">Κωδικος παραγγελιας</TableCell>
                    <TableCell align="left">Καταστήμα</TableCell>
                    <TableCell align="left">Υπεύθυνος παραλαβής</TableCell>
                    <TableCell align="left">Κωδικος πελάτη</TableCell>
                    <TableCell align="left">Ημερομηνια παραγγελιας</TableCell>
                    <TableCell align="left">Κατάσταση</TableCell>
                    <TableCell align="left">Εξέλιξη παραγγελίας</TableCell>
                    <TableCell align="right"></TableCell>
                </TableRow>
            </TableHead>

            <TableBody>
                {orders?.map((obj, idx) => (
                    <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={idx}
                    >
                        <TableCell component="th" scope="row">{obj._id}</TableCell>
                        <TableCell align="left">{obj.storeName}</TableCell>
                        <TableCell align="left">{obj.recipient}</TableCell>
                        <TableCell align="left">{obj.sellerCode}</TableCell>
                        <TableCell align="left">{new Date(obj.createdAt).toLocaleDateString()}</TableCell>
                        <TableCell align="left">
                            {obj.status === 'pending' ? <Chip color="primary" label="Σε Αναμονή" /> : ''}
                            {obj.status === 'accept' ? <Chip color="success" label="Εγκρίθηκε" /> : ''}
                            {obj.status === 'decline' ? <Chip color="error" label="Δεν εγκρίθηκε" /> : ''}
                        </TableCell>
                        <TableCell>

                            {obj.status === 'accept' ? (
                                <FormControl fullWidth sx={{ m: 1, minWidth: 120 }} size="small">
                                    <Select
                                        labelId="select-label"
                                        id="select"
                                        value={obj.deliveredStatus}
                                        onChange={(e) => handleChangeStatus(obj._id, e.target.value)}
                                        displayEmpty
                                    >
                                        <MenuItem value={'processing'}>Προς υλοποίηση</MenuItem>
                                        <MenuItem value={'packaging'}>Προετοιμασία αποστολής</MenuItem>
                                        <MenuItem value={'sending'}>Σε διαδικασία αποστολής</MenuItem>
                                        <MenuItem value={'delivered'}>Παραδόθηκε στον προορισμό</MenuItem>
                                    </Select>
                                </FormControl>
                            ) : ''}
                        </TableCell>
                        <TableCell align="right">
                            <Stack direction={'row'} spacing={2} justifyContent="flex-end">

                                {obj.status === 'accept' ? (
                                    <>
                                        <Button variant="outlined" size="medium" sx={{ textTransform: 'unset', borderRadius: '10px' }}
                                            onClick={() => navigate(`/single-order/${obj._id}`, { state: { id: obj._id } })}
                                        >Προβολή
                                        </Button>
                                        <Button
                                            onClick={() => handleOpen(obj._id, obj.emailSeller)}
                                            variant="contained" size="medium" sx={{ textTransform: 'unset', borderRadius: '10px' }} color="error">
                                            Ακύρωση
                                        </Button>
                                    </>

                                ) : ''}

                                {obj.status === 'decline' ? (
                                    <Button
                                        onClick={() => acceptOrder(obj._id, obj.emailSeller, obj.storeName)}
                                        variant="contained" size="medium" sx={{ textTransform: 'unset', borderRadius: '10px' }} color="success">Αποδοχή</Button>
                                ) : ''}

                                {obj.status === 'pending' ?
                                    (
                                        <>
                                            <Button
                                                onClick={() => acceptOrder(obj._id, obj.emailSeller, obj.storeName)}
                                                variant="contained" size="medium" sx={{ textTransform: 'unset', borderRadius: '10px' }} color="success">Αποδοχή</Button>
                                            <Button
                                                onClick={() => handleOpen(obj._id, obj.emailSeller)}
                                                variant="contained" size="medium" sx={{ textTransform: 'unset', borderRadius: '10px' }} color="error">Ακύρωση</Button>
                                        </>
                                    )
                                    : ''}
                            </Stack>
                        </TableCell>
                    </TableRow>
                )
                )}
            </TableBody>


            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h5" component="h2">
                        Αιτιολογία Απόρριψης
                    </Typography>
                    <Stack direction={'column'}>
                        <TextField onChange={(e) => setdDeclineMsg(e.target.value)} sx={{ mt: 5, mb: 2 }} size="medium" />

                        <Button
                            onClick={() => declineOrder(orderId, sellerEmail)}
                            variant="contained" size="medium" sx={{ textTransform: 'unset', borderRadius: '10px' }} color="error">
                            Αποστολή
                        </Button>
                    </Stack>

                </Box>
            </Modal>

        </Table>
    )
}

export default SellerTable