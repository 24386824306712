import { useState } from "react"
import { useNavigate, Link } from "react-router-dom"

// MUI
import {
    AppBar,
    Toolbar,
    Button,
    Stack,
    useMediaQuery,
    IconButton,
    Typography,
    Badge,
    Avatar
} from "@mui/material";

// Icons
import LogoutIcon from '@mui/icons-material/Logout';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

// Redux 
import { useDispatch, useSelector } from 'react-redux'
import { logout } from "../../redux/slices/Auth";

// Components
import Image from '../Helper/Image'
import CartDrawer from "../Cart/CartDrawer";

// Images
import LogoImg from "../../assets/img/ELEMENTS-11.png";
import LogoMain from "../../assets/img/logo_black.png";

const Topbar = () => {

    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.data)
    const status = useSelector((state) => state.auth.status)
    const items = useSelector((state) => state.cart.items)
    const isXs = useMediaQuery((theme) => theme.breakpoints.only("xs"));

    const [showCart, setShowCart] = useState(false)

    const handleLogout = () => {
        if (window.confirm('Are sure you want to logout')) {
            dispatch(logout());
            window.localStorage.removeItem('token');
        }
    }

    function stringAvatar(name) {
        return {
            sx: {
                bgcolor: '#fff',
                textTransform: 'uppercase',
                fontSize: '14px',
                width: 35,
                height: 35,
                fontWeight: 'bold'
            },
            children: `${name.split('')[0][0]}${name.split('')[1][0]}`,
        };
    }

    return (
        <>
            <CartDrawer showCart={showCart} setShowCart={setShowCart} />
            <AppBar position="fixed" sx={{ background: "#f2c301", zIndex: (theme) => theme.zIndex.drawer + 1 }} elevation={0}>
                <Toolbar
                    sx={{
                        justifyContent: "space-between",
                    }}
                >
                    <Stack direction="row" alignItems="center" spacing={4}>
                    <Link to='/'>
                            <Image
                                sx={{
                                    height: "50px",
                                }}
                                src={LogoMain}
                                alt="logo"
                            />
                        </Link>
                        <Link to='/'>
                            <Image
                                sx={{
                                    height: "60px",
                                }}
                                src={LogoImg}
                                alt="logo"
                            />
                        </Link>
                        <Stack direction="row">
                            <Typography variant="h6" color="#fff" mr={1}>PROFESSIONAL</Typography>
                            <Typography variant="h6" color="#11161a">CLOTHING</Typography>
                        </Stack>
                    </Stack>

                    <Stack direction="row" spacing={2}>
                        {isXs ? (
                            <IconButton color="primary" onClick={handleLogout}>
                                <LogoutIcon />
                            </IconButton>
                        ) : (
                            <>
                                <Stack direction="row" alignContent='center' spacing={5}>
                                    <IconButton onClick={() => setShowCart(!showCart)}>
                                        <Badge badgeContent={items.length} color="error">
                                            <ShoppingCartIcon sx={{ fontSize: '30px' }} />
                                        </Badge>
                                    </IconButton>
                                    <Stack direction={'row'} spacing={1.5} sx={{ display: 'flex', alignItems: 'center' }}>
                                        {user ?
                                            (
                                                <>
                                                    <Avatar {...stringAvatar(`${user.email}`)} />
                                                    <Stack>
                                                        <Typography sx={{ color: '#11161a', fontSize: '12px', fontWeight: 'bold', lineHeight: '1.3' }}>
                                                            {status === 'loaded' ? (user.name, user.fullname) : ''}
                                                        </Typography>
                                                        <Typography sx={{ color: '#11161a', fontSize: '12px', lineHeight: '1.3' }}>
                                                            {status === 'loaded' ? user.email : ''}
                                                        </Typography>
                                                    </Stack>
                                                </>
                                            ) : ''
                                        }
                                    </Stack>
                                    <Button
                                        onClick={handleLogout}
                                        variant="contained"
                                        size="large"
                                        sx={{
                                            background: '#11161a',
                                            color: '#fff',
                                            "&.MuiButtonBase-root:hover": {
                                                bgcolor: "#11161af0"
                                            }
                                        }}
                                        startIcon={<LogoutIcon />}>
                                        Αποσύνδεση
                                    </Button>
                                </Stack>
                            </>
                        )}
                    </Stack>
                </Toolbar>
            </AppBar>
        </>
    );
}

export default Topbar