import React from 'react'

//MUI
import { Container, Grid } from "@mui/material";

// Components
import Topbar from '../../components/Layout/Topbar'
import MenuCard from '../../components/Menu/MenuCard';

// Images
import Dashboard from '../../assets/img/ELEMENTS-02.png'
import Create from '../../assets/img/ELEMENTS-03.png'

const Home = () => {
  return (
    <>
      <Topbar />
      <Container
        maxWidth="lg"
        sx={{
          flexDirection: 'column',
          justifyContent: 'center',
          display: 'flex',
        }}
      >
        <Grid container spacing={4} direction='row' sx={{ height: '100vh', alignContent: 'center' }}>
          <Grid item xs={12} sm={6} md={6}
            sx={{
              borderRadius: '10px',
            }}>
            <MenuCard
              title={'ΠΙΝΑΚΑΣ ΕΛΕΓΧΟΥ'}
              icon={Dashboard}
              link={'dashboard'}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}
            sx={{
              borderRadius: '10px',
            }}
          >
            <MenuCard
              title={'ΔΗΜΙΟΥΡΓΙΑ'}
              icon={Create}
              link={'create'}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default Home