import React from 'react'
import { useNavigate } from "react-router-dom";

// Axios
import axios from '../../axios';

// MUI
import { Button, TextField, Box, Grid, Typography, Modal, Stack, OutlinedInput, InputLabel, MenuItem, FormControl, Select } from '@mui/material';

// redux
import { useDispatch, useSelector } from 'react-redux'
import { removeCartItemAll } from '../../redux/slices/CartSlice'

import { v4 as uuidv4 } from 'uuid';

// React form
import { useForm } from 'react-hook-form'

// Component
import Image from '../../components/Helper/Image';
import Topbar from '../../components/Layout/Topbar';

// Images
import backgroundImage from '../../assets/img/ELEMENTS-16.png'
import backgroundLogo from '../../assets/img/ELEMENTS-12.png'


// Modal styles component
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: '20px'
};

const Checkout = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const items = useSelector((state) => state.cart.items)
    const user = useSelector((state) => state.auth.data)
    const [personName, setPersonName] = React.useState([]);

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm({
        defaultValues: {
            nameShop: "",
            postCode: "",
            address: "",
            city: "",
            phone: "",
            recipient: "",
            sellerCode: "",
            customerBrand: "",
            approvers: []
        },
        mode: 'onChange',
    });

    const onSubmit = async (values) => {
        const orderData = {
            "orderId": uuidv4(),
            "storeName": values.nameShop,
            "address": values.address,
            "postCode": values.postCode,
            "city": values.city,
            "phone": values.phone,
            "receiver": values.recipient,
            "items": items,
            "sellerCode": values.sellerCode,
            "emailSeller": user.email,
            "nameSeller": user.name,
            "surnameSeller": user.surname,
            "customerBrand": values.customerBrand,
            "approvers": personName
        }
        await axios.post('/orders', orderData)

        console.log(orderData)

        dispatch(removeCartItemAll())
        handleOpen()
    }

    const handleCloseModal = () => {
        handleClose()
        navigate('/')
    }

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const names = [
        'Oliver Hansen',
        'Van Henry',
        'April Tucker',
        'Ralph Hubbard',
        'Omar Alexander',
    ];

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    return (
        <>
            <Topbar />
            <Grid container component="main" sx={{ height: '100vh', display: 'flex', justifyContent: 'center' }}>
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={6}
                    sx={{
                        backgroundImage: `url(${backgroundImage})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        position: 'relative',
                    }}
                >
                    <Image src={backgroundLogo}
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={8} md={6} elevation={6}>
                    <Box
                        sx={{
                            height: '100%',
                            mx: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center'
                        }}
                    >
                        <Box textAlign='left'>
                            <Typography component="h1" variant="h4">ΦΟΡΜΑ ΑΠΟΣΤΟΛΗΣ</Typography>
                        </Box>
                        <Box noValidate sx={{ mt: 5, input: { color: '#f2c300' } }}>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                    <Grid item xs={6}>
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            label="Εμπορική Επωνυμία Καταστήματος"
                                            name="nameShop"
                                            autoFocus
                                            error={Boolean(errors.nameShop?.message)}
                                            helperText={errors.nameShop?.message}
                                            {...register('nameShop', { required: 'Please enter shop name' })}
                                        />
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            label="Επωνυμία Πελάτη"
                                            name="customerBrand"
                                            autoFocus
                                            error={Boolean(errors.customerBrand?.message)}
                                            helperText={errors.customerBrand?.message}
                                            {...register('customerBrand', { required: 'Please enter customer brand' })}
                                        />
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            name="postCode"
                                            label="Ταχυδρομικός κώδικας"
                                            error={Boolean(errors.postCode?.message)}
                                            helperText={errors.postCode?.message}
                                            {...register('postCode', { required: 'Please enter post code' })}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            name="address"
                                            label="Διεύθυνση"
                                            error={Boolean(errors.address?.message)}
                                            helperText={errors.address?.message}
                                            {...register('address', { required: 'Please enter address' })}
                                        />
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            name="city"
                                            label="Πόλη"
                                            error={Boolean(errors.city?.message)}
                                            helperText={errors.city?.message}
                                            {...register('city', { required: 'Please enter city' })}
                                        />
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            name="phone"
                                            label="Τηλέφωνο"
                                            error={Boolean(errors.phone?.message)}
                                            helperText={errors.phone?.message}
                                            {...register('phone', { required: 'Please enter phone' })}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            name="recipient"
                                            label="Όνοματεπώνυμο υπεύθυνου παραλαβής"
                                            error={Boolean(errors.recipient?.message)}
                                            helperText={errors.recipient?.message}
                                            {...register('recipient', { required: 'Please enter recipient name' })}
                                        />
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            name="sellerCode"
                                            label="Κωδικος πελάτη"
                                            error={Boolean(errors.sellerCode?.message)}
                                            helperText={errors.sellerCode?.message}
                                            {...register('sellerCode', { required: 'Please enter customer code' })}
                                        />
                                        <FormControl sx={{ mt:3}}>
                                            <InputLabel id="multiple-name-label">Επιλογή approvers</InputLabel>
                                            <Select
                                                labelId="multiple-name-label"
                                                id="multiple-name"
                                                multiple
                                                value={personName}
                                                onChange={handleChange}
                                                input={<OutlinedInput label="approvers" />}
                                                MenuProps={MenuProps}
                                                sx={{ minWidth: '200px'}}
                                            >
                                                {names.map((name) => (
                                                    <MenuItem
                                                        key={name}
                                                        value={name}
                                                    >
                                                        {name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Stack direction={'row'} spacing={4} mt={5}>
                                    <Button
                                        onClick={() => navigate(-1)}
                                        size="large"
                                        fullWidth
                                        variant="contained"
                                        sx={{
                                            mt: 3,
                                            mb: 2,
                                            fontSize: '20px'
                                        }}
                                    >
                                        Ακύρωση
                                    </Button>
                                    <Button
                                        disabled={!isValid}
                                        size="large"
                                        fullWidth
                                        variant="contained"
                                        type="submit"
                                        sx={{
                                            mt: 3,
                                            mb: 2,
                                            fontSize: '20px'
                                        }}
                                    >
                                        Αποστολή
                                    </Button>
                                </Stack>

                            </form>
                        </Box>
                    </Box>
                </Grid>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h5" component="h2">
                            Ευχαριστούμε
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 3 }}>
                            Η παραγγελία σας βρίσκεται σε στάδιο αναμονής έγκρισης
                        </Typography>
                        <Button
                            onClick={handleCloseModal}
                            size="large"
                            fullWidth
                            variant="contained"
                            sx={{
                                mt: 5,
                                mb: 2,
                                fontSize: '20px'
                            }}
                        >
                            Κλείσιμο
                        </Button>
                    </Box>
                </Modal>
            </Grid >
        </>
    )
}

export default Checkout
