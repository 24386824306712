import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from '../../axios';

// Register
export const fetchRegister = createAsyncThunk(
    'auth/fetchRegister',
    async (params) => {
        const { data } = await axios.post('/auth/register', params)
        return data;
    },
);

// Login
export const fetchAuth = createAsyncThunk(
    'auth/fetchAuth',
    async (params) => {
        const { data } = await axios.post('/auth/login', params)
        return data;
    },
);

// Get me
export const fetchAuthMe = createAsyncThunk(
    'auth/fetchAuthMe',
    async () => {
        const { data } = await axios.get('/auth/me')
        return data;
    },
);

// Update user cart
export const updateAuthCart = createAsyncThunk(
    'auth/updateAuthCart',
    async (params) => {
        const { data } = await axios.patch('/auth/update/cart', params)
        return data;
    },
);

// Update user cart
export const updateAuth = createAsyncThunk(
    'auth/updateAuth',
    async (params) => {
        const { data } = await axios.patch('/auth/update', params)
        return data;
    },
);

const initialState = {
    data: null,
    status: 'loading',
};

const authSlice = createSlice({
    name: 'Auth',
    initialState,
    reducers: {
        logout: (state) => {
            state.data = null;
        }
    },
    extraReducers: (builder) => {
        builder

            // Register
            .addCase(fetchRegister.pending, (state) => {
                state.status = 'loading';
                state.data = null;
            })
            .addCase(fetchRegister.fulfilled, (state, action) => {
                state.status = 'loaded';
                state.data = action.payload;
            })
            .addCase(fetchRegister.rejected, (state, action) => {
                state.status = 'error';
                state.data = null;
            })

            // Login
            .addCase(fetchAuth.pending, (state) => {
                state.status = 'loading';
                state.data = null;
            })
            .addCase(fetchAuth.fulfilled, (state, action) => {
                state.status = 'loaded';
                state.data = action.payload;
            })
            .addCase(fetchAuth.rejected, (state, action) => {
                state.status = 'error';
                state.data = null;
            })

            // Get me
            .addCase(fetchAuthMe.pending, (state) => {
                state.status = 'loading';
                state.data = null;
            })
            .addCase(fetchAuthMe.fulfilled, (state, action) => {
                state.status = 'loaded';
                state.data = action.payload;
            })
            .addCase(fetchAuthMe.rejected, (state, action) => {
                state.status = 'loading';
                state.data = null;
            })

            // Update user cart
            .addCase(updateAuth.pending, (state) => {
                state.status = 'loading';
                state.data = null;
            })
            .addCase(updateAuth.fulfilled, (state, action) => {
                state.status = 'loaded';
                state.data = action.payload;
            })
            .addCase(updateAuth.rejected, (state, action) => {
                state.status = 'error';
                state.data = null;
            })

            // Update user cart
            .addCase(updateAuthCart.pending, (state) => {
                state.status = 'loading';
                state.data = null;
            })
            .addCase(updateAuthCart.fulfilled, (state, action) => {
                state.status = 'loaded';
                state.data = action.payload;
            })
            .addCase(updateAuthCart.rejected, (state, action) => {
                state.status = 'error';
                state.data = null;
            })
    }
});

export const selectIsAuth = state => state.auth.data;

export const { logout } = authSlice.actions;
export const authReducer = authSlice.reducer;
