import React from 'react'

import { Container, TextField, Paper, Button, Stack, Grid } from '@mui/material';

// Redux 
import { useDispatch, useSelector } from 'react-redux'
import { updateAuth, fetchAuthMe } from "../../../../redux/slices/Auth";

// React form
import { useForm } from 'react-hook-form'

// Toast
import { toast } from 'react-toastify';

const Profile = () => {

  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.data)

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      name: "",
      surname: "",
      phone: "",
      address: "",
    },
    mode: 'onChange',
  });

  const onSubmit = async (values) => {
    const data = await dispatch(updateAuth(values));
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12}>
        <Container maxWidth="false" component={Paper} sx={{ borderRadius: '10px' }}>
          <Stack pt={2}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6}>
                  <TextField
                    margin="normal"
                    fullWidth
                    name="name"
                    autoFocus
                    size='small'
                    defaultValue={user.name}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="surname"
                    size='small'
                    defaultValue={user.surname}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="phone"
                    size='small'
                    defaultValue={user.phone}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="address"
                    size='small'
                    defaultValue={user.address}
                  />
                  <Button variant="contained" size="medium" sx={{ textTransform: 'unset', marginTop: '30px', mb: 3, borderRadius: '10px' }}>Αποθηκευση</Button>
                </Grid>
              </Grid>
            </form>
          </Stack>
        </Container>
      </Grid>
    </Grid>
  )
}

export default Profile