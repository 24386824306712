import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import axios from '../../../../axios'

import { Table, TableCell, TableContainer, TableHead, TableBody, TableRow, Paper, Button, Stack, Grid, Typography, Chip } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux'
import { fetchOrders } from "../../../../redux/slices/Order";

// Tostofy
import { ToastContainer, toast } from 'react-toastify';

const Shops = () => {

  const navigate = useNavigate()

  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.auth.data)

  const orders = useSelector(state => state.order.items)

  const [filter, setFilter] = useState()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    dispatch(fetchOrders())
    setLoading(false)
  }, [loading])

  // Filter function
  useEffect(() => {
    function getUniqueListBy(orders, key) {
      return [...new Map(orders.map(item => [item[key], item])).values()]
    }
    setFilter(getUniqueListBy(orders, 'emailSeller'))
  }, [])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12}>
        <TableContainer component={Paper} sx={{ borderRadius: '10px' }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Καταστήμα</TableCell>
                <TableCell align="left">Διεύθυνση</TableCell>
                <TableCell align="left">Ταχυδρομικός κώδικας</TableCell>
                <TableCell align="left">Πόλη</TableCell>
                <TableCell align="left">Τηλέφωνο</TableCell>
                <TableCell align="left">Όνοματεπώνυμο υπεύθυνου παραλαβής</TableCell>
                <TableCell align="left">Κωδικος πελάτη</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders?.map((obj, idx) =>
                obj.emailSeller === userAuth.email ? (
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="left">{obj.storeName}</TableCell>
                    <TableCell align="left">{obj.address}</TableCell>
                    <TableCell align="left">{obj.postCode}</TableCell>
                    <TableCell align="left">{obj.city}</TableCell>
                    <TableCell align="left">{obj.phone}</TableCell>
                    <TableCell align="left">{obj.recipient}</TableCell>
                    <TableCell align="left">{obj.sellerCode}</TableCell>
                  </TableRow>) : ('')
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export default Shops