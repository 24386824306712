import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom"

// MUI
import { Stack, Drawer, Box, Typography, IconButton, Button } from '@mui/material'

// redux
import { useDispatch, useSelector } from 'react-redux'

// Tostofy
import { toast } from 'react-toastify';

// Components
import CartItem from "./CartItem";

// Icons
import CloseIcon from '@mui/icons-material/Close';
import EmptyCart from './EmptyCart';


const CartDrawer = ({ showCart, setShowCart }) => {

    const items = useSelector((state) => state.cart.items)

    const navigate = useNavigate();

    const handleCheckout = () => {

        if (items.length >= 1) {
            navigate('/checkout')
        } else {
            toast.info('Προσθέστε κάποιο προϊόν', {
                position: "top-center",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                progress: undefined,
                theme: "light",
            });
        }
    }


    return (
        <Drawer
            PaperProps={{
                sx: {
                    backgroundColor: "#fff",
                    color: "#000",
                }
            }}
            anchor="right"
            open={showCart}
            elevation={4}
        >
            <Box px={4} py={4} width='450px'
                sx={{
                    height: '100vh',
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'column'
                }}>
                <Stack>
                    <Stack pt={7} pb={4} direction='row' sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant='h3'>Καλάθι</Typography>
                        <IconButton onClick={() => setShowCart(false)}>
                            <CloseIcon sx={{ color: '#949d9f' }} />
                        </IconButton>
                    </Stack>
                    <Stack>
                        {!items.length ? <EmptyCart /> : (
                            items.map((item) => (
                                <CartItem key={item.id} {...item} />
                            ))
                        )}
                    </Stack>
                </Stack>
                <Stack>
                    <Button
                        onClick={handleCheckout}
                        variant="contained"
                        size="large"
                        sx={{
                            background: '#11161a',
                            color: '#fff',
                            "&.MuiButtonBase-root:hover": {
                                bgcolor: "#11161af0"
                            }
                        }}
                    >
                        Αποστολή
                    </Button>
                </Stack>
            </Box>
        </Drawer>
    )
}

export default CartDrawer