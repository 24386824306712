import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate, Link } from 'react-router-dom';

import axios from '../../../../axios'

import { Container, Table, TableCell,  TableHead, TableBody, TableRow, Paper, Button, Stack, Grid, Typography, Chip, Modal, Box } from '@mui/material';

import Topbar from '../../../../components/Layout/Topbar';
import ButtonBack from '../../../../components/Helper/ButtonBack';

import { useDispatch, useSelector } from 'react-redux'

import DownloadIcon from '@mui/icons-material/Download';

import { usePDF } from 'react-to-pdf';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const SingleOrder = () => {

    const { toPDF, targetRef } = usePDF({ filename: 'order.pdf' });

    const { state } = useLocation();
    const navigate = useNavigate()

    const [data, setData] = useState([])
    const [open, setOpen] = useState(false);
    const [logo, setLogo] = useState('')

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const fetchData = async () => {
        const result = await axios(
            `/orders/${state.id}`,
        );
        return result;
    };

    useEffect(() => {
        fetchData().then(res => {
            setData(res.data)
        })
    }, [])

    const showFunction = (url) => {
        handleOpen()
        setLogo(url)
    }

    return (
        <>
            <Topbar />

            <Container
                maxWidth="xl"
                sx={{
                    flexDirection: 'column',
                    justifyContent: 'center',
                    display: 'flex',
                }}>
                <Grid container spacing={4} direction='row' sx={{ height: '100%' }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>

                            <Container maxWidth="xl" sx={{ borderRadius: '10px', mt: 20 }}>
                                <Stack direction={'row'} sx={{ justifyContent: 'space-between' }}>
                                    <Stack direction={'row'} spacing={2}>
                                        <Typography variant='h5'>ΣΤΟΙΧΕΙΑ ΠΑΡΑΓΓΕΛΙΑΣ</Typography>
                                        {state.status === 'pending' ? <Chip color="primary" label="Σε Αναμονή" /> : ''}
                                        {state.status === 'accept' ? <Chip color="success" label="Ολοκληρώθηκε" /> : ''}
                                        {state.status === 'decline' ? <Chip color="error" label="Ακυρώθηκε" /> : ''}
                                    </Stack>
                                    <Button
                                        startIcon={<DownloadIcon />}
                                        onClick={() => toPDF()}
                                        variant="outlined" color="primary">
                                        Download PDF
                                    </Button>
                                </Stack>
                            </Container>

                            <Container maxWidth="xl" component={Paper} sx={{ borderRadius: '10px', mt: 5, background: '#fff', color: '#000' }} ref={targetRef}>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                    <Grid item xs={12}>
                                        <Table>
                                            <TableRow>
                                                <TableCell variant="head" sx={{ color: '#000' }}>Κωδικος παραγγελιας</TableCell>
                                                <TableCell sx={{ textAlign: 'right' }}>{data.orderId}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell variant="head" sx={{ color: '#000' }}>Πωλητής</TableCell>
                                                <TableCell sx={{ textAlign: 'right' }}>{data.nameSeller}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell variant="head" sx={{ color: '#000' }}>Ονομα καταστηματος</TableCell>
                                                <TableCell sx={{ textAlign: 'right' }}>{data.storeName}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell variant="head" sx={{ color: '#000' }}>Διεύθυνση</TableCell>
                                                <TableCell sx={{ textAlign: 'right' }}>{data.address}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell variant="head" sx={{ color: '#000' }}>Ταχυδρομικός Κώδικας</TableCell>
                                                <TableCell sx={{ textAlign: 'right' }}>{data.postCode}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell variant="head" sx={{ color: '#000' }}>Πόλη</TableCell>
                                                <TableCell sx={{ textAlign: 'right' }}>{data.city}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell variant="head" sx={{ color: '#000' }}>Τηλέφωνο</TableCell>
                                                <TableCell sx={{ textAlign: 'right' }}>{data.phone}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={{ borderBottom: 'none', color: '#000' }} variant="head">Υπεύθυνος παραλαβής</TableCell>
                                                <TableCell sx={{ textAlign: 'right', borderBottom: 'none' }}>{data.recipient}</TableCell>
                                            </TableRow>
                                        </Table>
                                    </Grid>
                                </Grid>
                            </Container>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} mb={10}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Container maxWidth="xl" sx={{ borderRadius: '10px', mt: 5 }}>
                                <Typography variant='h5'>ΠΕΡΙΓΡΑΦΗ</Typography>
                            </Container>

                            <Container maxWidth="xl" component={Paper} sx={{ borderRadius: '10px', mt: 5 }}>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                    <Grid item xs={12}>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left">Τίτλος</TableCell>
                                                    <TableCell align="left">Χρωμα γιακας</TableCell>
                                                    <TableCell align="left">Λογότυπο πελάτη</TableCell>
                                                    <TableCell align="left">Μέγεθος</TableCell>
                                                    <TableCell align="left">Ποσότητα</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>

                                                {data.items?.map((obj, idx) => (
                                                    <TableRow
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">{obj.category} {obj.title}</TableCell>
                                                        <TableCell align="left">{obj.colorName}</TableCell>
                                                        <TableCell align="left">
                                                            <Stack direction={'row'} spacing={2}>
                                                                <Button
                                                                    startIcon={<DownloadIcon />}
                                                                    component={Link} to={obj.logo} target="_blank" variant="outlined" color="primary">
                                                                    Λήψη
                                                                </Button>
                                                                <Button
                                                                    onClick={() => showFunction(obj.logo)}
                                                                    variant="outlined" color="primary">
                                                                    Προβολή
                                                                </Button>
                                                            </Stack>
                                                        </TableCell>
                                                        <TableCell align="left">{obj.size}</TableCell>
                                                        <TableCell align="left">{obj.quntity}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                </Grid>
                            </Container>
                        </Grid>
                    </Grid>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img src={logo} />
                            </Box>
                        </Box>
                    </Modal>
                </Grid>
            </Container>
            <Container sx={{ marginTop: '-50px' }} maxWidth={false} >
                <ButtonBack />
            </Container>
        </>
    )
}

export default SingleOrder