import React, { useEffect, useState } from 'react'
import { useLocation } from "react-router-dom"
import { useCustomization } from "../../Context/CustomizerContext";
import { Stack, Typography, Box, Divider, Button, styled, IconButton, ButtonGroup, Radio, RadioGroup, FormControl, FormControlLabel, FormLabel } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

// Axios
import axios from '../../axios'

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { addCartItem } from '../../redux/slices/CartSlice';

// Icons
import CloseIcon from '@mui/icons-material/Close';

// Components
import Image from '../../components/Helper/Image';
import ButtonBack from '../../components/Helper/ButtonBack';

// Tostofy
import { ToastContainer, toast } from 'react-toastify';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const Configurator = () => {

    const items = useSelector((state) => state.cart.items)

    const { state } = useLocation();
    const dispatch = useDispatch()

    const [quntityCart, setQuntityCart] = useState(1);
    const [count, setCount] = useState(1)
    const [size, setSize] = useState('M')

    const handleIncrement = () => {
        setCount(Math.min(count + 1, 3));
    };

    const handleDecrement = () => {
        setCount(Math.max(count - 1, 1));
    };

    useEffect(() => {
        function filterByCity(arr, category) {
            return arr.filter(function (item, i, arr) {
                return (item.category == category);
            });
        };
        var categoryName = filterByCity(items, 'ΚΙΜΟΝΟ');
        categoryName.map((user) => setQuntityCart(Math.min(quntityCart + user.quntity)))
    }, [items])

    console.log(quntityCart)

    const addItemHandler = () => {

        if (quntityCart >= 4) {
            toast.success('Maximum cart', {
                position: "top-center",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            const item = {
                id: uuidv4(),
                title: state.title,
                color: collarColor.color,
                colorName: collarColor.name,
                logo: logoClientUrl === '/logo/clear.png' ? 'ΧΩΡΙΣ LOGO' : logoClientUrl,
                category: state.category,
                size: size,
                quntity: count,
            }
            dispatch(addCartItem(item))

            toast.success('Προστέθηκε στο καλάθι', {
                position: "top-center",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                progress: undefined,
                theme: "light",
            });
        }

    }

    const {
        collarColors,
        collarColor,
        setCollarColor,
        logoClientUrl,
        setLogoClientUrl
    } = useCustomization();

    const handleChangeFile = async (event) => {
        try {
            const formData = new FormData();
            const file = event.target.files[0]
            formData.append('image', file);
            const { data } = await axios.post('/upload', formData);
            const UploadImage = (`https://schweppesapp-59dd58e1e291.herokuapp.com${data.url}`);
            console.log(UploadImage)
            setLogoClientUrl(UploadImage)
        } catch (err) {
            console.warn(err);
            alert('Error upload file')
        }
    }

    const handleRemoveFile = () => {
        setLogoClientUrl('/logo/clear.png');
    }

    return (
        <Stack sx={{ p: 3, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100vh' }}>
            <Stack>
                <Stack py={2}>
                    <Typography variant='h3' mb={2} sx={{ fontSize: '24px', fontWeight: '700' }}>{state.name}</Typography>
                    <Typography variant='body'>{state.description}
                    </Typography>
                </Stack>
                <Divider />
                <Stack py={3}>
                    <Typography variant='h5' mb={1} sx={{ fontSize: '18px' }}>Γιακάς</Typography>
                    <Stack direction='row' spacing={3} py={1}>
                        {collarColors.map((item, index) => (
                            <Box
                                sx={{
                                    cursor: "pointer",
                                    border: item.color === collarColors.color ? "4px solid #fff" : "4px solid #fff",
                                    outline: item.color === collarColor.color ? "4px solid #ced0d1" : "none",
                                }}
                                key={index}
                                height={35}
                                width={35}
                                backgroundColor={item.color}
                                borderRadius="50%"
                                onClick={() => setCollarColor(item)}
                            />
                        ))}
                    </Stack>
                </Stack>
                <Divider />
                <Stack py={3}>
                    <Typography variant='h5' mb={1} sx={{ fontSize: '18px' }}>Λογότυπο πελάτη</Typography>
                    <Stack direction='row' spacing={3} py={1}>
                        <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
                            <Button component="label" variant="contained" size="large"
                                sx={{
                                    background: '#f2c301',
                                    "&.MuiButtonBase-root:hover": {
                                        bgcolor: "#f2c301"
                                    }
                                }}>
                                Upload logo
                                <VisuallyHiddenInput onChange={handleChangeFile} type="file" />
                            </Button>
                            <IconButton onClick={handleRemoveFile}>
                                <CloseIcon sx={{ color: '#949d9f' }} />
                            </IconButton>
                        </Stack>
                        <Divider orientation="vertical" />
                        <Stack>
                            <Image src={logoClientUrl} sx={{ width: '250px' }} />
                        </Stack>
                    </Stack>
                </Stack>
                <Divider />
                <Stack py={3} direction={'row'} spacing={10}>
                    <Stack >
                        <Typography variant='h5' sx={{ fontSize: '18px' }}>Ποσότητα</Typography>
                        <Typography variant='caption'>*Μέγιστη ποσότητα 3 τεµάχια</Typography>
                        <Stack direction='row' spacing={2} py={3}>
                            <ButtonGroup size="medium" direction='row' sx={{ alignItems: 'center' }}>
                                {<Button onClick={handleDecrement} variant="contained">-</Button>}
                                {<Typography variant="h5" px={2} sx={{ fontSize: '16px' }}>{count}</Typography>}
                                <Button onClick={handleIncrement} variant="contained">+</Button>
                            </ButtonGroup>
                        </Stack>
                    </Stack>
                    <Stack>
                        <Typography variant='h5' sx={{ fontSize: '18px' }} mb={3}>Μέγεθος</Typography>
                        <FormControl>
                            <RadioGroup
                                defaultValue="M"
                                name="radio-buttons-group"
                            >
                                <FormControlLabel value="S" control={<Radio />} label="S" onChange={(e) => setSize(e.target.value)} />
                                <FormControlLabel value="M" control={<Radio />} label="M" onChange={(e) => setSize(e.target.value)} />
                                <FormControlLabel value="L" control={<Radio />} label="L" onChange={(e) => setSize(e.target.value)} />
                                <FormControlLabel value="XL" control={<Radio />} label="XL" onChange={(e) => setSize(e.target.value)} />
                            </RadioGroup>
                        </FormControl>
                    </Stack>
                </Stack>
            </Stack>
            <Stack py={2}>
                <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} >
                    <ButtonBack />
                    <Button
                        onClick={addItemHandler}
                        component="label"
                        variant="contained"
                        size="large"
                        sx={{
                            padding: '8px 40px',
                            background: '#000000',
                            color: '#ffffff',
                            "&.MuiButtonBase-root:hover": {
                                bgcolor: "#000000"
                            }
                        }}>
                        Προσθήκη
                    </Button>
                </Stack>
            </Stack>
        </Stack >
    )
}

export default Configurator