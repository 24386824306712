import { useLocation } from 'react-router-dom';

// MUI
import { Drawer, Toolbar } from '@mui/material'

// Configurator
import ConfiguratorApon from '../../models/apon/Configurator';
import ConfiguratorKimono from '../../models/kimono/Configurator';

const drawerWidth = 520;

const Sidebar = () => {

    const { state } = useLocation();

    const renderProductConf = () => {
        if (state.category === 'ΠΟΔΙΕΣ') return (<ConfiguratorApon {...state} />)
        if (state.category === 'ΚΙΜΟΝΟ') return (<ConfiguratorKimono {...state} />)
    }

    return (
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                },
            }}
            variant="permanent"
            anchor="left"
            PaperProps={{
                sx: {
                    backgroundColor: "#ffffff",
                    color: "#000000",
                }
            }}
        >
            <Toolbar />
            {renderProductConf()}
        </Drawer>
    )
}

export default Sidebar