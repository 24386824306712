import React, { useState, useEffect } from 'react';

import { Table, TableCell, TableContainer, TableHead, TableBody, TableRow, Paper, Button, Stack, Grid, Typography, Chip } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux'
import { fetchOrders } from "../../../../redux/slices/Order";

import AdminTable from './Table/AdminTable';
import SellerTable from './Table/SellerTable';

const Orders = () => {

  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.auth.data)

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    dispatch(fetchOrders())
    setLoading(false)
  }, [loading])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12}>
        <TableContainer component={Paper} sx={{ borderRadius: '10px' }}>
          {
            userAuth && userAuth.role === 'admin' ? (
              <>
                <AdminTable />
              </>
            ) : (
              <>
                <SellerTable />
              </>
            )
          }
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export default Orders