import React, { useEffect, useState } from 'react'
import { Stack, Container, Paper, Grid, Typography, Table, TableHead, TableCell, TableRow, TableBody } from '@mui/material';


import { useDispatch, useSelector } from 'react-redux'
import { fetchOrders } from "../../../../redux/slices/Order";

import Chart from './Chart';

const Reports = () => {

  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.auth.data)
  const orders = useSelector(state => state.order.items)

  useEffect(() => {
    dispatch(fetchOrders())
  }, [])


  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12}>
        <Container maxWidth="false" sx={{ mb: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  height: 240,
                  marginRight: '24px'
                }}
              >
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Κωδικος</TableCell>
                      <TableCell>Ημερομηνια παραγγελιας</TableCell>
                      <TableCell>Όνομα Πωλητή</TableCell>
                      <TableCell>Καταστήμα</TableCell>
                      <TableCell>Κωδικος Πωλητή</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>

                    {userAuth.role === 'admin' ? (
                      orders?.map((obj, idx) => (
                        <TableRow>
                          <TableCell>{obj._id}</TableCell>
                          <TableCell>{new Date(obj.createdAt).toLocaleDateString()}</TableCell>
                          <TableCell>{obj.nameSeller}</TableCell>
                          <TableCell>{obj.storeName}</TableCell>
                          <TableCell>{obj.sellerCode}</TableCell>
                        </TableRow>
                      ))
                    ) : ''}

                    {orders?.map((obj, idx) => (
                      obj.emailSeller === userAuth.email ? (
                        <TableRow>
                          <TableCell>{obj._id}</TableCell>
                          <TableCell>{new Date(obj.createdAt).toLocaleDateString()}</TableCell>
                          <TableCell>{obj.nameSeller}</TableCell>
                          <TableCell>{obj.storeName}</TableCell>
                          <TableCell>{obj.sellerCode}</TableCell>
                        </TableRow>
                      ) : ('')
                    ))}

                  </TableBody>
                </Table>
              </Paper>
            </Grid>

          </Grid>
        </Container>
      </Grid>
    </Grid>
  )
}

export default Reports