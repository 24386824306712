import React from 'react'
import { useNavigate } from "react-router-dom"

import Image from './Image'

// Images
import backImage from '../../assets/img/ELEMENTS-10.png'

const ButtonBack = () => {
    const navigate = useNavigate()
    return (
        <Image src={backImage} onClick={() => navigate(-1)} sx={{ height: '35px', cursor: 'pointer' }} />
    )
}

export default ButtonBack