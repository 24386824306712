import axios from 'axios';

const instance = axios.create({
    baseURL: 'https://schweppesapp-59dd58e1e291.herokuapp.com',
    //baseURL: 'http://localhost:3001/',
});

instance.interceptors.request.use((config) => {
    config.headers.Authorization = window.localStorage.getItem('token');
    return config;
});

export default instance;