import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import axios from '../../../../../axios'

import { Table, TableCell, TableContainer, TableHead, TableBody, TableRow, Paper, Button, Stack, Grid, Chip } from '@mui/material';

// Redux 
import { useDispatch, useSelector } from 'react-redux'
import { fetchUsers } from "../../../../../redux/slices/User";

// Tostofy
import { ToastContainer, toast } from 'react-toastify';

const Users = () => {

    const navigate = useNavigate()

    const dispatch = useDispatch();
    const users = useSelector(state => state.user.items)
    const userAuth = useSelector((state) => state.auth.data)
    const orders = useSelector((state) => state.orders)

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        dispatch(fetchUsers())
        setLoading(false)
    }, [loading])

    const activateUser = async (id) => {
        axios.patch(`/users/${id}/activate`);

        toast.success('Activete user', {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            progress: undefined,
            theme: "light",
        });
        setLoading(true)
    }

    const declineUser = async (id) => {
        axios.delete(`/users/${id}`);

        toast.success('Delete user', {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            progress: undefined,
            theme: "light",
        });
        setLoading(true)
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
                <TableContainer component={Paper} sx={{ borderRadius: '10px' }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Κωδικος πωλητη</TableCell>
                                <TableCell align="left">Ονοματεπώνυμο</TableCell>
                                <TableCell align="left">Email</TableCell>
                                <TableCell align="left">Τηλέφωνο</TableCell>
                                <TableCell align="left">Περιοχή</TableCell>
                                <TableCell align="left">Κατάσταση</TableCell>
                                <TableCell align="right">Καταστηματα</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {orders?.map((obj, idx) =>
                                userAuth.role === 'seller' ? (
                                    <TableRow
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={idx}
                                    >
                                        <TableCell component="th" scope="row">ID567456</TableCell>
                                        <TableCell align="left">{obj.name} {obj.surname}</TableCell>
                                        <TableCell align="left">{obj.email}</TableCell>
                                        <TableCell align="left">{obj.phone}</TableCell>
                                        <TableCell align="left">{obj.address}</TableCell>
                                        <TableCell align="left">
                                            {obj.isActivated === false ? <Chip color="primary" label="Δεν εγκρίθηκε" /> : ''}
                                            {obj.isActivated === true ? <Chip color="success" label="Εγκρίθηκε" /> : ''}
                                        </TableCell>
                                        <TableCell align="right">
                                            <Stack direction={'row'} spacing={2} justifyContent="flex-end">

                                                {obj.isActivated != true ? (
                                                    <>
                                                        <Button
                                                            onClick={() => activateUser(obj._id)}
                                                            variant="outlined" color='success' size="medium" sx={{ textTransform: 'unset', borderRadius: '10px' }} >Αποδοχή
                                                        </Button>
                                                        <Button
                                                            onClick={() => declineUser(obj._id)}
                                                            variant="outlined" color='error' size="medium" sx={{ textTransform: 'unset', borderRadius: '10px' }} >Ακύρωση
                                                        </Button>
                                                    </>
                                                ) : (
                                                    <Button
                                                        variant="outlined" size="medium" sx={{ textTransform: 'unset', borderRadius: '10px' }} >Προβολή
                                                    </Button>
                                                )}

                                            </Stack>
                                        </TableCell>
                                    </TableRow>) : ('')
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    )
}

export default Users