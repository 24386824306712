import { Stack, Typography } from '@mui/material'
import React from 'react'
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';

const EmptyCart = () => {
    return (
        <Stack sx={{ display: 'flex', height: '65vh', justifyContent: 'center', alignItems: 'center' }}>
            <RemoveShoppingCartIcon sx={{ color: '#cbcbcb', fontSize: '60px', marginBottom: '10px' }} />
            <Typography variant='h5'>Το καλάθι σας είναι άδειο</Typography>
        </Stack>
    )
}

export default EmptyCart